import * as type from './types';
import property from 'lodash/property';
import axios from "axios";
import {_sendLog} from "../../utils/utility";

const authCpHeader = (state) => {
    const cpToken = _getCpToken(state);
    if (cpToken) {
        return { 'Authorization': 'Bearer ' + cpToken };
    } else {
        return {};
    }
}

export function _showError(errors) {
    console.log(errors);
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_ERROR,
            errors: errors
        });
    }
}


export function _clearError() {
    return (dispatch, getState) => {
        dispatch({
            type: type.CLEAR_ERROR
        });
    }
}


export function _showModal(data) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_MODAL,
            data: data
        });
    }
}

export function _hideModal() {
    return (dispatch, getState) => {
        dispatch({
            type: type.HIDE_MODAL,
        });
    }
}


export function _showSpinner() {
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_SPINNER
        });
    }
}

export function _hideSpinner() {
    return (dispatch, getState) => {
        dispatch({
            type: type.HIDE_SPINNER
        });
    }
}

export function _setLang(lang) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_LANG,
            lang: lang?.toLowerCase()
        });
    }
}

export const _setOtpToken = (otpToken) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_OTP_TOKEN,
            data: otpToken
        });
    }
}

export const _setCpToken = (cpToken, iv) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_CP_TOKEN,
            token: cpToken
        });
    }
}

export const _verifyOtp = (otp) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    otp: otp
                }
            }

            axios.get(process.env.REACT_APP_VERIFY_OTP, config)
                .then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    _sendLog(['Supplier Portal React - System Service - _verifyOtp', JSON.stringify(error)],
                        'error');
                    reject(error);
            })
        })
    }
}


export const _verifyOtpAndGetUserDetail = (otp, email, action) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    otp: otp,
                    email: email.toLowerCase(),
                    country: _getCountry(getState()),
                    action: action
                },
                timeout: 30000
            }

            axios.get(process.env.REACT_APP_VERIFY_OTP_AND_GET_USER_DETAIL, config)
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: type.SET_USER,
                            data: response.data.userDetails
                        })
                    }
                    resolve(response.data);
                }).catch((error) => {
                    _sendLog(['Supplier Portal React - System Service - _verifyOtpAndGetUserDetail', JSON.stringify(error)],
                        'error');
                    reject(error);
                }
            )
        })
    }
}

export function _setNoLogin(noLogin) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_NO_LOGIN,
            noLogin: noLogin
        });
    }
}

export function _setCountry(country) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_COUNTRY,
            country: country
        });
    }
}

export function _clearPortfolioToken() {
    return (dispatch, getState) => {
        dispatch({
            type: type.RESET_PORTFOLIO_TOKEN
        });
    }
}


export function _clearToken() {
    return (dispatch, getState) => {
        console.log("clear tokens");
        dispatch({
            type: type.RESET_STATE
        });
    }
}

export function _setShowOtpModal(value) {
    return (dispatch) => {
        dispatch({
            type: type.SET_SHOW_OTP_MODAL,
            showOtpModal: value
        });
    }
}

export const _generatePortfolioToken = (userId, clientId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let config = {
                params: {
                    userId: userId,
                    clientId: clientId
                }
            }

            axios.get(process.env.REACT_APP_GENERATE_PORTFOLIO_ACCESS_TOKEN, config).then(
                resp => {
                    if (resp.data.success) {
                        dispatch({
                            type: type.SET_PORTFOLIO_TOKEN,
                            data: resp.data.customerPortalToken
                        });
                    }
                    resolve();
                }
            ).catch(error => {
                _sendLog(['Supplier Portal React - System Service - _generatePortfolioToken', JSON.stringify(error)],
                    'error');
                reject(error);
            })
        });
    }
}

export const _setPortfolioToken = (token) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_PORTFOLIO_TOKEN,
            data: token
        });
    }
}

export const _clearState = () => {
    return (dispatch) => {
        dispatch({
            type: type.RESET_STATE
        });
    }
}

export const _setShowPageBroken = (value) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_PAGE_BROKEN,
            showPageBroken: value
        });
    }
}

export const _setAdminLogin = (value) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_ADMIN_LOGIN,
            adminLogin: value
        })
    }
}

export const _setTimerStarted = (value, id) => {
    return (dispatch) => {
        dispatch({
            type: type.SET_TIMER_STARTED,
            data: {
                value: value,
                id: id
            }
        })
    }
}

export function _setClaimsToken(token) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_CLAIMS_TOKEN,
            token: token
        });
    }
}

export function _resetClaimsToken() {
    return (dispatch, getState) => {
        dispatch({
            type: type.RESET_CLAIMS_TOKEN
        });
    }
}

export function _setDocumentToken(token) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_DOCUMENT_TOKEN,
            token: token
        });
    }
}

export function _resetDocumentToken() {
    return (dispatch, getState) => {
        dispatch({
            type: type.RESET_DOCUMENT_TOKEN
        });
    }
}

export const _checkSystemStatus = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const httpOptions = { headers: {'Content-Type': 'application/json'} };
            axios.get(process.env.REACT_APP_CHECK_SYSTEM_STATUS + "/" + _getCountry(getState()), httpOptions)
                .then((response) => {
                    const systemAvailable = response.data?.toString() === "true";
                    resolve(systemAvailable);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - _checkSystemStatus', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
};

export const _getStatutoryHolidaysList = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                params: {
                    country: _getCountry(getState()),
                },
                timeout: 60000
            };

            axios.get(process.env.REACT_APP_GET_STATUTORY_HOLIDAYS_LIST, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - System Service - _getStatutoryHolidaysList', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _saveOrDeleteStatutoryHoliday = (holiday, action) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: authCpHeader(getState()),
                timeout: 600000
            }

            const requestBody = {
                holidayDetails: holiday,
                action: action
            };

            axios.post(process.env.REACT_APP_SAVE_OR_DELETE_STATUTORY_HOLIDAY, requestBody, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    _sendLog(['Supplier Portal React - System Service - _saveStatutoryHoliday', JSON.stringify(error)],
                        'error');
                    reject(error);
                })
        })
    }
}

export const _getErrors = property('sys.errors');

export const _getShowError = property('sys.showError');

export const _getShowModal = property('sys.showModal');

export const _getModal = property('sys.modal');

export const _getShowSpinner = property('sys.spinner');

export const _getViewOnly = property('sys.viewOnly');

export const _getTransactionType = property('sys.transactionType');

export const _getLang = property('sys.lang');

export const _getToken = property('sys.otpToken');

export const _getCpToken = property('sys.cpToken');

export const _getClaimsToken = property('sys.claimsToken');

export const _getDocumentToken = property('sys.documentToken');

export const _isNoLogin = property('sys.noLogin');

export const _getCountry = property('sys.country');

export const _isHongKong = property('sys.country') === 'HKG';

export const _isSingapore = property('sys.country') === 'SGP';

export const _getShowOtpModal = property('sys.showOtpModal');

export const _getPortfolioToken = property('sys.portfolioToken');

export const _getShowPageBroken = property('sys.showPageBroken');

export const _getAdminLogin = property('sys.adminLogin');

export const _isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const _getTimerStarted = property('sys.timerStarted');

export const _getTimerId = property('sys.timerId');
