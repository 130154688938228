import React, {useEffect, useState} from "react";
import QnectTextField from "../qnect-ui/qnect-text-field";
import FieldWrapper from "../custom/field-wrapper";
import QnectButton from "../qnect-ui/qnect-button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../custom/dialog-title";
import DialogSecondaryTitle from "../custom/dialog-secondary-title";
import Dialog from '../custom/dialog';
import {useDialogStyles} from "../custom/dialog-style";
import useConfig from "../qnect-ui/qnect-config";
import { _validateForm } from "../../utils/utility";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import QnectDateField from "../qnect-ui/qnect-date-field";
import moment from "moment";

export const AddEditHolidayDialog = ({open = false, onClose, onOk, rowData, country, ...props}) => {
    const classes = useDialogStyles();
    const cfg = useConfig();
    const formId = 'addEditHolidayForm';

    const [holidayId, setHolidayId] = useState('');
    const [holidayCountry, setHolidayCountry] = useState(null);
    const [holidayName, setHolidayName] = useState(null);
    const [holidayDate, setHolidayDate] = useState(null);
    const [holidayDateErrorMessage, setHolidayDateErrorMessage] = useState('');

    const isAdd = rowData === null ? true : false;
    
    useEffect(() => {
        if (isAdd) {
            setHolidayId('');
            setHolidayCountry(country);
            setHolidayName(null);
            setHolidayDate(null);
        } else {
            setHolidayId(rowData?.id);
            setHolidayCountry(rowData?.country);
            setHolidayName(rowData?.holidayName);
            setHolidayDate(moment(rowData?.holidayDate), 'DD/MM/YYYY');
        }
        setHolidayDateErrorMessage('');
    }, [rowData, open, country, isAdd]);

    const onClickOk = () => {
        if (_validateForm(formId) && validateDate()) {
            let obj = {
                id: holidayId,
                country: holidayCountry,
                holidayName: holidayName,
                holidayDate: moment(holidayDate).format('YYYY-MM-DD')
            }
            onOk(obj);
        }
    }

    const validateDate = () => {
        setHolidayDateErrorMessage('');
        let currentYear = moment().format('YYYY');
        let inputYear = moment(holidayDate).format('YYYY');
        
        if (inputYear < currentYear) { //checks if past year
            setHolidayDateErrorMessage(cfg('statutoryHolidays.holidayDate.errorMessages.inputYearIsPreviousYear'));
            return false;
        }
        return true;
    }

    return (
        <div>
            <Dialog open={open} onClose={onClose} className={classes.modal_box_container}>
                <DialogTitle className={classes.title}
                    title={
                        <div>
                            {isAdd &&
                            <>
                                <AddCircleOutlineIcon fontSize='large'/><br/>
                                {cfg("statutoryHolidays.addHoliday.title")}
                            </>}
                            {!isAdd &&
                            <>
                                <EditIcon fontSize='large'/><br/>
                                {cfg("statutoryHolidays.editHoliday.title")}
                            </>}
                        </div>}
                        closable={false} lang={props.lang}
                />
                <DialogSecondaryTitle title={cfg("statutoryHolidays.holidaySubTitle")} />
                <DialogContent>
                     <div className={classes.modal_box_form_container}>
                        <div className='modal_box_form_field input_holidayName'>
                            <FieldWrapper>
                                <QnectTextField
                                    formId={formId}
                                    field="statutoryHolidays.holidayName"
                                    value={holidayName}
                                    maxLength={80}
                                    onChange={ val => {
                                        setHolidayName(val.target.value)
                                    }}
                                />
                            </FieldWrapper>
                        </div>
                        <div className='modal_box_form_field input_holidayDate'>
                            <FieldWrapper >
                                <QnectDateField
                                    formId={formId}
                                    field="statutoryHolidays.holidayDate"
                                    value={holidayDate}
                                    onChange={(val) => setHolidayDate(val)}
                                    errorMessage={holidayDateErrorMessage}
                                />
                            </FieldWrapper>
                        </div>
                     </div>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <QnectButton onClick={onClose} field="cancel" />
                    <QnectButton onClick={() => onClickOk()} field="confirm"/>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddEditHolidayDialog;

