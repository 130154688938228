import React from 'react';
import Header from "../components/custom/header";
import SessionTimeout from "../components/session-timeout";
import ViewStatutoryHolidays from '../components/statutory-holidays/view-statutory-holidays';

const StatutoryHolidaysPage = () => {

    return (

        <div>
            <Header />
            <ViewStatutoryHolidays />
            <SessionTimeout />
        </div>
    )
}

export default (StatutoryHolidaysPage);
