import React, {useEffect, useState} from "react";
import styled from "styled-components";
import useStatutoryHolidaysStyles, {StyledModalDiv} from "./statutory-holidays-style";
import MaterialUiTable from "../ui/material-ui-table/material-ui-table";
import {_getUser} from "../../store/user/service";
import {_getCountry, _getLang, _hideSpinner, _showSpinner, 
    _showModal, _hideModal, _showError,
    _getStatutoryHolidaysList,
    _saveOrDeleteStatutoryHoliday} 
    from "../../store/system/service";
import {withRouter, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import Button from "../../ui/components/buttons/button";
import { Box, Hidden, Typography } from "@mui/material";
import { BackIcon, BackWrapper, TopWrapper } from "../claims-details/claims-details-style";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {_formatDate, formatDate, tagManagerPopup} from "../../utils/utility";
import { AddEditHolidayDialog } from "./add-edit-holiday-dialog";
import QnectButton from "../qnect-ui/qnect-button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {ReactComponent as Arrow} from "../../assets/Arrow.svg"
import moment from "moment";

const TitleDiv = styled.div`
    margin-top: 48px;
    font-family: Stag-Medium;
    color: #0A1F44;
    font-size: 32px;
    flex: '50%';
`;

const ViewStatutoryHolidays = (props) => {
    const cfg = useConfig(props.lang);
    const history = useHistory();
    const classes = useStatutoryHolidaysStyles(props);
    const [openAddEditHolidayDialog, setAddEditHolidayDialog] = useState(false);
    const [data, setData] = useState([]);
    const [holidayDetail, setHolidayDetail] = useState(null);

    useEffect(() => {
        loadStatutoryHolidaysList();
    }, []);


    const loadStatutoryHolidaysList = () => {
        props.showSpinner();
        props.getStatutoryHolidaysList().then((response) => {
            setData(response);
            props.hideSpinner();
        }, (error) => {
            console.log('loadStatutoryHolidaysList - error occurred', error);
            props.hideSpinner();
        })
    }

    const addEditHoliday = (holidayData) => {
        let modalTitle = '';
        let modalMsg = '';
        let errMsg = '';
        if (holidayDetail === null) {
            modalTitle = cfg('statutoryHolidays.addHoliday.modalTitle');
            modalMsg = cfg('statutoryHolidays.addHoliday.modalMsg');
            errMsg = cfg('statutoryHolidays.addHoliday.errMsg');
        } else {
            modalTitle = cfg('statutoryHolidays.editHoliday.modalTitle');
            modalMsg = cfg('statutoryHolidays.editHoliday.modalMsg');
            errMsg = cfg('statutoryHolidays.editHoliday.errMsg');
        }

        setAddEditHolidayDialog(false);
        props.showSpinner();
        props.saveOrDeleteStatutoryHoliday(holidayData, 'save').then((resp) => {
            props.hideSpinner();
            if (resp.success) {
                showModalSuccess(modalTitle, modalMsg);
                loadStatutoryHolidaysList();
            } else {
                props.showError([resp.errorDesc]);
                tagManagerPopup(resp.errorDesc);
            }
        }, (error) => {
            props.hideSpinner();
            if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                props.showError([cfg('errorMessage.internalServerError')]);
                tagManagerPopup(cfg('errorMessage.internalServerError'));
            } else {
                props.showError([errMsg]);
                tagManagerPopup(errMsg);
            }
        });
    }

    const removeHoliday = (data) => {
        let obj = {
            id: data.id,
            country: data.country,
            holidayName: data.holidayName,
            holidayDate: moment(data.holidayDate).format('YYYY-MM-DD')
        }
        props.showSpinner();
        props.saveOrDeleteStatutoryHoliday(obj, 'delete').then((resp) => {
            props.hideSpinner();
            if (resp.success) {
                showModalSuccess(cfg('statutoryHolidays.removeHoliday.modalTitle'), cfg('statutoryHolidays.removeHoliday.modalMsg'));
                loadStatutoryHolidaysList();
            } else {
                props.showError([resp.errorDesc]);
                tagManagerPopup(resp.errorDesc);
            }
        }, (error) => {
            props.hideSpinner();
            if (error.response.status === 500 || error.response.status === 400 || error.response.status === 404) {
                props.showError([cfg('errorMessage.internalServerError')]);
                tagManagerPopup(cfg('errorMessage.internalServerError'));
            } else {
                props.showError([cfg('statutoryHolidays.removeHoliday.errMsg')]);
                tagManagerPopup(cfg('statutoryHolidays.removeHoliday.errMsg'));
            }
        });
    }

    const callAddStatutoryHolidayDialog = () => {
        setHolidayDetail(null);
        setAddEditHolidayDialog(true);
        tagManagerPopup('Add Statutory Holiday');
    }

    const showRemoveHolidayModal = (data) => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="title">{cfg("statutoryHolidays.removeHoliday.title")}</div>
                <div className="subtitle">{cfg("statutoryHolidays.removeHoliday.areYouSure")}</div>
                <div style={{display:'inline-flex', gap:'20px', paddingTop:'20px'}}>
                    <QnectButton field="No" onClick={props.hideModal} />
                    <QnectButton field="Yes" onClick={() => {removeHoliday(data)}}/>
                </div>
            </StyledModalDiv>
        });
    }

    const showModalSuccess = (title, msg) => {
        props.showModal({
            flat: true,
            content: <StyledModalDiv>
                <div className="iconDivSuccess"><CheckCircleIcon fontSize='large'/></div>
                <div className="title">{title}</div>
                <div className="subtitle">{msg}</div>
                <div className="buttonDiv">
                    <QnectButton
                        field="close"
                        onClick={() => {props.hideModal();}}
                    />
                </div>
            </StyledModalDiv>
        });
        tagManagerPopup(title);
    }

    const ColumnAction = ({selectedRowData}) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
            
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        function callEditDialog(rowData) {
            setAnchorEl(null);
            setHolidayDetail(rowData);
            setAddEditHolidayDialog(true);
            tagManagerPopup('Edit Statutory Holiday');
        }

        function callRemoveDialog(rowData) {
            setAnchorEl(null);
            setHolidayDetail(rowData);
            showRemoveHolidayModal(rowData);
            tagManagerPopup('Remove Statutory Holiday');
        }

        return (
            <div>
                <Button color="secondary" onClick={handleClick} >
                    <div className={classes.whiteButtonStyle}>
                        <div style={{marginRight: "11px"}}>Actions</div>
                        <div style={{fill: '#1D4FBB'}}><Arrow/></div>
                    </div>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}>
                    <MenuItem onClick={() => {callEditDialog(selectedRowData)}}>Edit</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => {callRemoveDialog(selectedRowData)}}>Remove</MenuItem>
                </Menu>
            </div>
        )
    }

    const MyTitle = ({text, variant}) => (
        <div>
            <Typography variant={variant}
                style={{whitespace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: '100%'}}>
                    {text}
            </Typography>
        </div>
    );

    const columns = [
        {title: 'ID', field: 'id', sorting: false},
        {title: 'HOLIDAY NAME', field: 'holidayName', sorting: true},
        {title: 'HOLIDAY DATE', field: 'holidayDate', sorting: true,
            render: rowData => _formatDate(rowData.holidayDate)
        },
        {title: 'DATE MODIFIED', field: 'dateCreated',
            render: rowData => formatDate(rowData.dateCreated)
        },
        {title: '', sorting: false, render: (rowData) => (
            <ColumnAction selectedRowData={rowData}></ColumnAction>
        )}
    ];

    return (
        <div>
           <div className={classes.box}>
               <TopWrapper>
                   <Hidden mdDown>
                       <BackWrapper onClick={() => history.push("/adminDetails")}>
                           <BackIcon/>{cfg('backBtn.label')}
                       </BackWrapper>
                   </Hidden>
               </TopWrapper>
           </div>
            <div className={classes.page_container}>
                <div className ={classes.rowTitle}>
                    <TitleDiv>{cfg("statutoryHolidays.title")}</TitleDiv>
                </div>
                <br/>
                <div>
                    <Box sx={{ width: '100%' }}>
                        <MaterialUiTable 
                            columns={columns} data={data}
                            title={<MyTitle variant="h6" text=''/>}
                            onClickAdd={() => callAddStatutoryHolidayDialog()}
                            addText={cfg("statutoryHolidays.addHoliday.title")}
                        />
                    </Box>
                </div>
                <AddEditHolidayDialog open={openAddEditHolidayDialog}
                            onClose={() => setAddEditHolidayDialog(false)}
                            onOk={(holidayData) => addEditHoliday(holidayData)}
                            rowData={holidayDetail}
                            country={props.country}>
                </AddEditHolidayDialog>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        user: _getUser(state),
        lang: _getLang(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showModal: _showModal,
    hideModal: _hideModal,
    showError: _showError,
    getStatutoryHolidaysList: _getStatutoryHolidaysList,
    saveOrDeleteStatutoryHoliday: _saveOrDeleteStatutoryHoliday
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewStatutoryHolidays));
